import { Elt } from '../render'
import { Vec2 } from '../math'

export class Tile extends Elt {
  protected readonly inner: Elt = new Elt().addClass('tile-inner')

  constructor(pos: Vec2, outerClasses: string | string[], innerClasses: string | string[]) {
    super()
    this.addClass('tile')

    this.addClass(outerClasses)
    this.setPosition(pos)

    this.inner.addClass(innerClasses)
    this.addChild(this.inner)
  }
}
