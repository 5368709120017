import { BonusType, GameConstants } from '../game'
import { Elt, Animation, SequentialAnimation, ParallelAnimation, CallAnimation, Tween, Easing } from '../render'
import { AnimationTimings } from './constants'
import { Vec2 } from '../math'

export class ScoreBubble extends Elt {

  private readonly textElt: Elt
  private readonly duration: number

  private constructor(duration: number) {
    super()
    this.duration = duration

    this.addClass('score-bubble')
    this.setAnimated(true)

    this.textElt = new Elt()
    this.addChild(this.textElt)

    this.setScale(0)
    this.setOpacity(0)
  }

  createPopAnimation(parent: Elt): Animation {
    const durationIn = 0.5 * this.duration
    const durationOut = 0.5 * this.duration
    return new SequentialAnimation(
        new CallAnimation(() => parent.addChild(this)),
        new ParallelAnimation(
          new Tween({
            object: this,
            setter: this.setOpacity,
            from: 0 as number,
            to: 1 as number,
            duration: 0.4 * durationIn,
            easing: Easing.SINE_OUT,
          }),
          new Tween({
            object: this,
            setter: this.setScale,
            from: 0 as number,
            to: 1 as number,
            duration: durationIn,
            easing: Easing.SINE_OUT,
          })),
        new ParallelAnimation(
          new Tween({
            object: this,
            getter: this.getOpacity,
            setter: this.setOpacity,
            to: 0 as number,
            duration: durationOut,
            easing: Easing.SINE_IN,
          }),
          new Tween({
            object: this,
            getter: this.getScale,
            setter: this.setScale,
            to: 0.8 as number,
            duration: durationOut,
            easing: Easing.SINE_IN,
          })),
        new CallAnimation(this.removeFromParent.bind(this)))
  }

  static forBlock(score: number, order: number, pos: Vec2): ScoreBubble {
    const scoreBubble = new ScoreBubble(0.5 + 0.1 * order)
    scoreBubble.setPosition(pos)
    scoreBubble.textElt
        .addClass(`score order-${order}-color`)
        .setText(`+${score}`)
        .setStyle('font-size', `${0.7 + 0.1 * order}em`)
    return scoreBubble
  }

  static forBonus(score: number, type: BonusType): ScoreBubble {
    const scoreBubble = new ScoreBubble(AnimationTimings.BONUS_BUBBLE_DURATION)
    let text = null
    switch (type) {
      case BonusType.BLACKOUT:
        text = 'Blackout'
        break
      case BonusType.WHITEOUT:
        text = 'Whiteout'
        break
    }
    scoreBubble.setPosition(GameConstants.BOARD_SIZE.scl(0.5))
    scoreBubble.textElt
        .addClass(`score bonus bonus-${type}`)
        .setHtml(`${text}<br>+${score}`)
    return scoreBubble
  }
}
