import { GameConstants, QueueRenderingConstants, AnimationTimings } from './constants'
import { QueueState } from './state'
import { Block } from './block'
import { BlockGenerator } from './blockgenerator'
import { Random, GridSet, clamp, lerp, square, Vec2 } from '../math'
import { Animation, ParallelAnimation, SequentialAnimation, CallAnimation, NullAnimation, Elt, Easing, Tween } from '../render'

export class Queue extends Elt {

  private readonly generator: BlockGenerator
  private readonly blocks: Block[] = []

  // Position of the first block in the queue.
  private scrollPosition: number = 0
  // The scrollPosition that we are currently animating towards.
  private targetScrollPosition: number = 0
  private blocksIgnoredForPosition: Block[] = []

  private constructor(generator: BlockGenerator, blocks: Block[]) {
    super()
    this.addClass('queue')

    const glowSize = QueueRenderingConstants.QUEUE_NEXT_BLOCK_SIZE.scl(1.2)
    const glow = new Elt()
        .addClass('queue-glow')
        .setPosition(new Vec2(0, -QueueRenderingConstants.QUEUE_MIDDLE_RADIUS).sub(glowSize.scl(0.5)))
        .setSize(glowSize)
    this.addChild(glow)

    this.generator = generator
    this.blocks = blocks

    for (const block of this.blocks) {
      this.addChild(block)
    }
    this.updateBlockPositions()
  }

  static create(randomSeed: number): Queue {
    return new Queue(
      BlockGenerator.create(randomSeed),
      [])
  }

  static fromState(state: QueueState): Queue {
    return new Queue(
      BlockGenerator.fromState(state.generator),
      state.blocks.map(Block.fromState))
  }

  toState(): QueueState {
    return {
      generator: this.generator.toState(),
      blocks: this.blocks.map((block) => block.toState()),
    }
  }

  placeBlock(): Animation {
    const [block, animation] = this.popFront()
    return animation
  }

  stashBlock(): [Block, Animation] {
    return this.popFront()
  }

  unstashBlock(block: Block): Animation {
    const scrollAnimation = this.pushFront(block)
    return new ParallelAnimation(
        scrollAnimation,
        block.createAnimation(this.getTranslationForIndex(0), this.getScaleForIndex(0), AnimationTimings.STASH_DURATION))
  }

  refill(board: GridSet) {
    while (this.blocks.length < GameConstants.QUEUE_LENGTH) {
      this.addBlock(Block.create(this.generator.next(board)))
    }
    this.updateBlockPositions()
  }

  createInitialFillAnimation(): Animation {
    this.scrollPosition = GameConstants.QUEUE_LENGTH
    this.targetScrollPosition = GameConstants.QUEUE_LENGTH
    this.updateBlockPositions()
    return this.createScrollAnimation(0, AnimationTimings.QUEUE_INITIAL_FILL_DURATION)
  }

  isEmpty(): boolean {
    return this.blocks.length == 0
  }

  front(): Block | undefined {
    return this.blocks[0]
  }

  debugReplace(index: number, board: GridSet, order: number) {
    if (this.blocks[index]) {
      this.blocks[index].removeFromParent()
    }

    const polyomino = this.generator.generatePolyominoOfOrder(board, order)
    const block = Block.create(polyomino)
    this.blocks[index] = block

    this.scrollPosition = 0
    this.targetScrollPosition = 0
    this.updateBlockPositions()
  }

  private pushFront(block: Block): Animation {
    this.blocks.unshift(block)
    this.addChild(block)

    this.scrollPosition--
    this.targetScrollPosition--
    return new SequentialAnimation(
      new CallAnimation(() => this.blocksIgnoredForPosition.push(block)),
      this.createScrollAnimation(),
      new CallAnimation(() => this.blocksIgnoredForPosition.splice(this.blocksIgnoredForPosition.indexOf(block), 1)))
  }

  private popFront(): [Block, Animation] {
    const block = this.blocks.shift()
    if (!block) {
      throw new Error('Cannot pop from an empty queue')
    }
    block.removeFromParent()

    this.scrollPosition++
    this.targetScrollPosition++
    const animation = this.createScrollAnimation()

    return [block, animation]
  }

  private addBlock(block: Block) {
    this.addChild(block)
    this.blocks.push(block)
  }

  private createScrollAnimation(to: number = 0, duration: number = AnimationTimings.QUEUE_SCROLL_DURATION): Animation {
    if (to == this.targetScrollPosition) {
      // Already animating or done
      return new NullAnimation()
    }
    const animation = new Tween({
      object: this,
      from: this.targetScrollPosition,
      to: to,
      setter: this.setScrollPosition,
      duration: duration,
      easing: Easing.QUAD_IN_OUT,
    })
    this.targetScrollPosition = to
    return animation
  }

  private getScrollPosition(): number {
    return this.scrollPosition
  }

  private setScrollPosition(scrollPosition: number) {
    this.scrollPosition = scrollPosition
    this.updateBlockPositions()
  }

  private getTranslationForIndex(idx: number): Vec2 {
    return new Vec2(0, -QueueRenderingConstants.QUEUE_MIDDLE_RADIUS).rotated(2 * Math.PI * idx / QueueRenderingConstants.QUEUE_LENGTH)
  }

  private getScaleForIndex(idx: number): number {
    return lerp(QueueRenderingConstants.QUEUE_BLOCK_SCALE, QueueRenderingConstants.QUEUE_NEXT_BLOCK_SCALE, clamp(1 - idx, 0, 1))
  }

  private updateBlockPositions() {
    for (let i = 0; i < this.blocks.length; i++) {
      const block = this.blocks[i]
      if (this.blocksIgnoredForPosition.indexOf(block) >= 0) {
        continue
      }
      const pos = i + this.scrollPosition
      block.setTranslation(this.getTranslationForIndex(pos), false)
      block.setScale(this.getScaleForIndex(pos), false)
      block.updateTransform()
      block.setOpacity(QueueRenderingConstants.QUEUE_LENGTH - pos)
    }
  }

  showGameOver(duration: number): Animation {
    const animation = new ParallelAnimation()
    for (let i = 1; i < this.blocks.length; i++) {
      const block = this.blocks[i]
      animation.push(new Tween({
        object: block,
        getter: block.getOpacity,
        setter: block.setOpacity,
        to: 0.2,
        duration: duration,
        easing: Easing.SINE_IN_OUT,
      }))
    }
    return animation
  }
}
