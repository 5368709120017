import { Elt, Animation, ParallelAnimation, SequentialAnimation, CallAnimation, DelayAnimation, Tween, Easing } from '../render'
import { Vec2 } from '../math'

export class Hint extends Elt {

  constructor(pos: Vec2) {
    super()
    this
      .setPosition(pos)
      .addClass('hint')
      .setAnimated(true)
      .setOpacity(0)
  }

  createAnimation(): Animation {
    const duration = 1.2
    return new SequentialAnimation(
      new ParallelAnimation(
        new Tween({
          object: this,
          setter: this.setScale,
          from: 0.2 as number,
          to: 1 as number,
          duration: duration,
          easing: Easing.LINEAR,
        }),
        new Tween({
          object: this,
          setter: this.setOpacity,
          from: 1 as number,
          to: 0 as number,
          duration: duration,
          easing: Easing.QUAD_IN,
        })),
      new CallAnimation(() => this.removeFromParent()))
  }
}
