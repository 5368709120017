import { Store } from '../storage'

import { Howl, Howler } from 'howler'

export interface PlaybackOptions {
  volume?: number
  panning?: number
  pitch?: number
  pitchRandomization?: number
}

export class SoundEffect {

  public static enabled: boolean = true

  public readonly name: string | null

  private readonly sprite: IHowlProperties

  constructor(sprite: IHowlProperties, fileName?: string) {
    this.sprite = sprite
    this.name = fileName ? fileName.replace(/\..*?$/, '') : null
  }

  load(callback: (error: string | null) => void) {
    const howl = new Howl(this.sprite)
    if (howl.state() == 'loaded') {
      callback(null)
    } else {
      howl.once('load', () => callback(null))
      howl.once('loaderror', (id: number, error: string) => callback(error))
    }
  }

  play(options: PlaybackOptions = {}) {
    if (!SoundEffect.enabled) {
      return
    }
    const props: Partial<IHowlProperties> = {}
    if (options.volume !== undefined) {
      props.volume = options.volume
    }
    if (options.panning !== undefined) {
      // stereo prop is missing from .ts.d
      (props as any).stereo = options.panning
    }
    props.rate = 1.0
    if (options.pitch !== undefined) {
      props.rate = options.pitch
    }
    if (options.pitchRandomization !== undefined) {
      props.rate *= 1.0 + (2 * Math.random() - 1) * options.pitchRandomization
    }
    new Howl({ ...this.sprite, ...props }).play(this.name || undefined)
  }
}
