import { GameConstants } from './constants'
import { GridSet } from '../math'
import { Polyomino } from './polyomino'
import { Random } from '../math'
import { BlockGeneratorState } from './state'
import { square } from '../math'

export interface BlockGeneratorOptions {
  nextSetSize: number
  meanInitial: number
  meanDivisor: number
  meanExponent: number
  stdDevInitial: number
  stdDevDivisor: number
  stdDevExponent: number
}

export class BlockGenerator {
  private readonly random: Random
  private readonly options: BlockGeneratorOptions
  private nextIndex: number = 0
  private readonly strengths: number[] = []

  private constructor(random: Random, nextIndex: number, strengths: number[], options: BlockGeneratorOptions = GameConstants.BLOCK_GENERATOR_OPTIONS) {
    this.random = random
    this.options = options
    this.nextIndex = nextIndex
    this.strengths = strengths
  }

  static create(randomSeed: number, options: BlockGeneratorOptions = GameConstants.BLOCK_GENERATOR_OPTIONS): BlockGenerator {
    const strengths = []
    for (let order = 1; order <= GameConstants.MAX_POLYOMINO_ORDER; order++) {
      strengths[order] = 0
    }
    return new BlockGenerator(
      Random.create(randomSeed),
      0,
      strengths)
  }

  static fromState(state: BlockGeneratorState): BlockGenerator {
    return new BlockGenerator(
      Random.fromState(state.random),
      state.nextIndex,
      state.strengths)
  }

  toState(): BlockGeneratorState {
    return {
      random: this.random.toState(),
      nextIndex: this.nextIndex,
      strengths: this.strengths.slice(0),
    }
  }

  next(board: GridSet): Polyomino {
    const order = this.nextOrder(this.nextIndex)
    return this.generatePolyominoOfOrder(board, order)
  }

  // Public only for debugging.
  generatePolyominoOfOrder(board: GridSet, order: number) {
    const all = Polyomino.allOfOrder(order)
    let polyomino
    do {
      polyomino = this.random.arrayElement(all)
    } while (!polyomino.fitsIn(board))
    return polyomino
  }

  private nextOrder(nextIndex: number): number {
    const orders = []
    for (let order = 1; order <= GameConstants.MAX_POLYOMINO_ORDER; order++) {
      this.strengths[order] += this.baseStrength(this.nextIndex, order)
      orders.push(order)
    }

    // Sort descending
    orders.sort((a, b) => this.strengths[b] - this.strengths[a])
    let order = orders[this.random.int(this.options.nextSetSize)]
    
    this.strengths[order] = 0
    this.nextIndex += 1
    return order
  }

  private baseStrength(nextIndex: number, order: number): number {
    // https://docs.google.com/spreadsheets/d/1wUmwDWv2t0yB5L56Khohz84oERfuHMLPpe463vnCeNc/edit#gid=0
    const mean = this.options.meanInitial + Math.pow(nextIndex / this.options.meanDivisor, this.options.meanExponent)
    const stdDev = this.options.stdDevInitial + Math.pow(nextIndex / this.options.stdDevDivisor, this.options.stdDevExponent)
    return Math.exp(-square(order - mean) / (2 * square(stdDev))) / Math.sqrt(2 * Math.PI * square(stdDev))
  }
}
