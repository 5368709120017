import { Elt } from '../render'
import { Animation, SequentialAnimation, CallAnimation, Tween, Easing } from '../render'

export class ScreenFlash extends Elt {
  constructor() {
    super()
    this.addClass('fullscreen screen-flash')
    this.setAnimated(true)
    this.setOpacity(0)
  }

  createAnimation(): Animation {
    return new SequentialAnimation(
        new Tween({
          object: this,
          setter: this.setOpacity,
          from: 1 as number,
          to: 0 as number,
          duration: 0.5,
          easing: Easing.QUAD_OUT,
        }),
        new CallAnimation(() => this.removeFromParent()))
  }
}
