import { PcgRandom } from './pcgrandom'

export type RandomState = number[]

export class Random {
  private readonly pcg: PcgRandom

  private constructor(pcg: PcgRandom) {
    this.pcg = pcg
  }

  static create(seed?: number) {
    return new Random(new PcgRandom(seed))
  }

  static fromState(state: RandomState) {
    const pcg = new PcgRandom()
    pcg.setState(state)
    return new Random(pcg)
  }

  toState(): RandomState {
    return this.pcg.getState()
  }

  int(max: number): number {
    return this.pcg.integer(max)
  }

  int32(): number {
    return this.pcg.next()
  }

  float(min: number, max: number) {
    return min + (max - min) * this.pcg.number()
  }

  boolean(prob: number) {
    return this.pcg.number() < prob
  }

  arrayElement<T>(array: T[]): T {
    if (array.length == 0) {
      throw new Error('Cannot get random element of zero-length array')
    }
    return array[this.int(array.length)]
  }
}

export const GLOBAL_RANDOM = Random.create()
