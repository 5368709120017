import { GameConstants, AnimationTimings } from './constants'
import { Polyomino } from './polyomino'
import { BoardState } from './state'
import { Random, GridMap, GridSet, Vec2, modBetween } from '../math'
import { Elt, Animation, ANIMATION_MANAGER, ParallelAnimation, SequentialAnimation, DelayAnimation, SoundAnimation, Easing, Tween } from '../render'
import { Sounds } from '../audio'

export enum TileColor {
  BLACK = 0,
  WHITE = 1,
}

function inverseColor(color: TileColor): TileColor {
  switch (color) {
    case TileColor.BLACK: return TileColor.WHITE
    case TileColor.WHITE: return TileColor.BLACK
  }
}

export class Board extends Elt {

  private readonly random: Random
  private readonly tiles: GridMap<Tile> = new GridMap<Tile>()

  private constructor(random: Random, tiles: GridMap<Tile>) {
    super()
    this
      .addClass('board')
      .setPosition(GameConstants.BOARD_SIZE.scl(-0.5))
      .setSize(GameConstants.BOARD_SIZE)

    this.random = random
    this.tiles = tiles
    for (const tile of this.tiles.values()) {
      this.addChild(tile)
    }
  }

  static create(randomSeed: number): Board {
    const random = Random.create(randomSeed)
    const tiles = new GridMap<Tile>()
    for (const pos of GameConstants.BOARD_TILES.keys()) {
      const color = random.boolean(0.5) ? TileColor.BLACK : TileColor.WHITE
      tiles.set(pos, new Tile(pos, color))
    }
    return new Board(
      random,
      tiles)
  }

  static fromState(state: BoardState): Board {
    const tiles = new GridMap<Tile>()
    for (const y in state.tiles) {
      for (const x in state.tiles[y]) {
        const pos = new Vec2(parseInt(x), parseInt(y))
        const color = state.tiles[y][x]
        tiles.set(pos, new Tile(pos, color))
      }
    }
    return new Board(
      Random.fromState(state.random),
      tiles)
  }

  toState(): BoardState {
    const tiles: number[][] = []
    for (const pos of this.tiles.keys()) {
      if (!tiles[pos.y]) {
        tiles[pos.y] = []
      }
      tiles[pos.y][pos.x] = this.getTileColor(pos)!
    }

    return {
      random: this.random.toState(),
      tiles: tiles,
    }
  }

  spinTiles(): Animation {
    const animation = new ParallelAnimation()
    for (const tile of this.tiles.values()) {
      animation.push(tile.spin(tile.getColor()))
    }
    return animation
  }

  tileSet(): GridSet {
    return new GridSet(this.tiles.keys())
  }

  containsTile(pos: Vec2): boolean {
    return this.tiles.contains(pos)
  }

  getTileColor(pos: Vec2): TileColor | undefined {
    const tile = this.tiles.get(pos)
    return tile ? tile.getColor() : undefined
  }

  flipTiles(polyomino: Polyomino, polyominoPos: Vec2): Animation {
    const animation = new ParallelAnimation()
    for (const offset of polyomino.tiles) {
      const pos = polyominoPos.add(offset)
      const tile = this.tiles.get(pos)!
      const color = inverseColor(tile.getColor())
      const distance = offset.length()
      animation.push(tile.flip(
          color,
          distance * AnimationTimings.FLIP_WAVE_DELAY,
          // distance^2 is an integer, so this is a whole number of semitones.
          Math.pow(2, distance * distance / 12)))
    }
    return animation
  }

  placementErrors(polyomino: Polyomino, polyominoPos: Vec2): Array<Vec2> {
    const desiredColor = this.getTileColor(polyominoPos)
    if (desiredColor === undefined) {
      return polyomino.tiles.slice(0)
    }
    const errors = []
    for (const tile of polyomino.tiles) {
      const pos = polyominoPos.add(tile)
      const t = this.getTileColor(pos)
      if (t === undefined || t != desiredColor) {
        errors.push(tile)
      }
    }
    return errors
  }

  canPlaceAt(polyomino: Polyomino, pos: Vec2): boolean {
    return this.placementErrors(polyomino, pos).length == 0
  }

  placeablePositions(polyomino: Polyomino): Vec2[] {
    const positions = []
    for (const pos of this.tiles.keys()) {
      if (this.canPlaceAt(polyomino, pos)) {
        positions.push(pos)
      }
    }
    return positions
  }

  canPlaceAnywhere(polyomino: Polyomino): boolean {
    return this.placeablePositions(polyomino).length > 0
  }

  getUniformColor(): TileColor | null {
    let color = null
    for (const pos of this.tiles.keys()) {
      const tile = this.getTileColor(pos)!
      if (color == null) {
        color = tile
      } else if (color != tile) {
        return null
      }
    }
    return color
  }

  posFromEvent(e: MouseEvent | Touch): Vec2 {
    const relativePos = this.getRelativeMousePos(e.clientX, e.clientY)
    return relativePos.floor()
  }

  showGameOver(duration: number): Animation {
    const animation = new ParallelAnimation()
    for (const tile of this.tiles.values()) {
      animation.push(new Tween({
        object: tile,
        getter: tile.getOpacity,
        setter: tile.setOpacity,
        to: 0.2,
        duration: duration,
        easing: Easing.SINE_IN_OUT,
      }))
    }
    return animation
  }
}

class Tile extends Elt {

  private color: TileColor

  // We have a separate element for the black and the white side so we can
  // avoid expensive repaints during animation. Both elements are in the DOM,
  // but only one has a nonzero opacity.
  private blackSide: Elt = new Elt().addClass('tile-inner black')
  private whiteSide: Elt = new Elt().addClass('tile-inner white')

  private flipRotation!: number

  constructor(pos: Vec2, color: TileColor) {
    super()
    this.color = color

    this.addClass('tile board-tile')

    this.setPosition(pos)
    this.addChild(this.blackSide)
    this.addChild(this.whiteSide)

    this.setFlipRotation(Tile.targetFlipRotation(color))
  }

  getColor(): TileColor {
    return this.color
  }

  spin(color: TileColor): Animation {
    this.color = color

    const duration = AnimationTimings.BOARD_RANDOMIZE_DURATION
    const flips = AnimationTimings.BOARD_RANDOMIZE_TILE_FLIPS
    const to = Tile.targetFlipRotation(color)
    const from = to - 90 - flips * 360
    this.setFlipRotation(from)
    const sounds = new SequentialAnimation()
    for (let i = 0; i < flips; i++) {
      const delay = duration / flips * (1 + 0.2 * (Math.random() * 2 - 1))
      sounds.push(
          new DelayAnimation(delay / 2),
          new SoundAnimation(Sounds.TILE_FLIP, {
            volume: 0.2,  
            panning: (this.getPosition().x / GameConstants.BOARD_SIZE.x) * 2 - 1,
            pitchRandomization: 0.1,
          }),
          new DelayAnimation(delay / 2))
    }
    return new SequentialAnimation(
        new DelayAnimation(Math.random() * AnimationTimings.BOARD_RANDOMIZE_DELAY),
        new ParallelAnimation(
          new Tween({
            object: this,
            setter: this.setFlipRotation,
            from: from,
            to: to,
            duration: duration,
            easing: Easing.SINE_IN_OUT,
          }),
          sounds))
  }

  flip(color: TileColor, delay: number, pitch: number) {
    this.color = color
    let to = Tile.targetFlipRotation(color)
    while (to < this.flipRotation) {
      to += 360
    }
    return new SequentialAnimation(
        new DelayAnimation(delay),
        new ParallelAnimation(
            new Tween({
              object: this,
              setter: this.setFlipRotation,
              from: this.flipRotation,
              to: to,
              duration: AnimationTimings.FLIP_DURATION,
              easing: Easing.SINE_IN_OUT,
            }),
            new SoundAnimation(Sounds.TILE_FLIP, {
              volume: 0.2,
              panning: (this.getPosition().x / GameConstants.BOARD_SIZE.x) * 2 - 1,
              pitch: pitch,
              pitchRandomization: 0.01,
            })))
  }

  private setFlipRotation(degrees: number) {
    degrees = modBetween(degrees, 0, 360)
    this.flipRotation = degrees
    this.blackSide.setTransform(`perspective(5em) rotate3d(1, -1, 0, ${degrees}deg)`)
    this.whiteSide.setTransform(`perspective(5em) rotate3d(1, -1, 0, ${degrees + 180}deg)`)
  }

  private static targetFlipRotation(color: TileColor): number {
    switch (color) {
      case TileColor.BLACK: return 0
      case TileColor.WHITE: return 180
    }
  }
}
