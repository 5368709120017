import { Board, TileColor } from './board'
import { Queue } from './queue'
import { Stash } from './stash'
import { Block } from './block'
import { EventSender } from '../utils'
import { GameState } from './state'
import { GameConstants, InteractionConstants, AnimationTimings, StashRenderingConstants } from './constants'
import { GameOverScreen } from './gameoverscreen'
import { debugEnabled, GeneratorDisplay } from '../debug'
import { Ghost } from './ghost'
import { Hint } from './hint'
import { square, Vec2, Random, GLOBAL_RANDOM } from '../math'
import { Sounds } from '../audio'
import { ScoreBubble } from './scorebubble'
import { Elt, Timeline, ParallelAnimation, SequentialAnimation, CallAnimation, NullAnimation, SoundAnimation, DelayAnimation, Animation } from '../render'

export enum BonusType {
  BLACKOUT = 'blackout',
  WHITEOUT = 'whiteout',
}

export class Game extends Elt {

  // Emitted after "Continue" was clicked so undo won't happen anymore.
  public readonly finished = new EventSender<void>()

  public readonly started = new EventSender<void>()
  public readonly moveStarting = new EventSender<void>()
  public readonly moveEnded = new EventSender<void>()

  private readonly center: Elt
  private readonly board: Board
  private readonly queue: Queue
  private readonly stash: Stash
  private ghost: Ghost | null = null

  private readonly scoreView: Elt
  private readonly movesView: Elt
  private gameOverScreen: GameOverScreen | null = null

  private readonly mouseDown = this.onMouseDown.bind(this)
  private readonly mouseMove = this.onMouseMove.bind(this)
  private readonly mouseUp = this.onMouseUp.bind(this)
  private readonly touchStart = this.onTouchStart.bind(this)
  private readonly touchMove = this.onTouchMove.bind(this)
  private readonly touchEnd = this.onTouchEnd.bind(this)
  private readonly touchCancel = this.onTouchCancel.bind(this)

  private trackedTouchId: number | null = null
  private lastMoveTime: number | null = null
  private hintTimeoutId: number | null = null

  private readonly timeline: Timeline = new Timeline()

  private score: number = 0
  private moves: number = 0

  private constructor(score: number, moves: number, board: Board, queue: Queue, stash: Stash) {
    super()

    this.score = score
    this.moves = moves
    this.board = board
    this.queue = queue
    this.stash = stash

    this.addClass('game')

    this.stash.clicked.on(this.toggleStash.bind(this))

    this.center = new Elt().addClass('game-center')
    this.addChild(this.center)

    this.center.addChild(this.stash)
    this.center.addChild(this.queue)
    this.center.addChild(this.board)

    this.scoreView = new Elt().addClass('info score').setText(`${this.score}`)
    this.addChild(new Elt().addClass('infobox bottom left')
        .addChild(new Elt().addClass('info-label').setText('Score'))
        .addChild(this.scoreView))

    this.movesView = new Elt().addClass('info moves').setText(`${this.moves}`)
    this.addChild(new Elt().addClass('infobox bottom right')
        .addChild(new Elt().addClass('info-label').setText('Moves'))
        .addChild(this.movesView))

    this.board.addEventListener('mousedown', this.mouseDown)
    this.board.addEventListener('touchstart', this.touchStart, {passive: false})
    if (debugEnabled()) {
      window.addEventListener('keydown', this.onDebugKeyDown.bind(this))
    }

    this.queue.refill(this.board.tileSet())
    this.checkGameOver()
  }

  static create(randomSeed: number) {
    const random = Random.create(randomSeed)
    const game = new Game(
      0,
      0,
      Board.create(random.int32()),
      Queue.create(random.int32()),
      Stash.create())
    game.animateStart()
    return game
  }

  static fromState(state: GameState) {
    return new Game(
      state.score,
      state.moves,
      Board.fromState(state.board),
      Queue.fromState(state.queue),
      Stash.fromState(state.stash))
  }

  toState(): GameState {
    return {
      score: this.score,
      moves: this.moves,
      board: this.board.toState(),
      queue: this.queue.toState(),
      stash: this.stash.toState(),
    }
  }

  private animateStart() {
    this.runOnTimeline(
      new ParallelAnimation(
        this.board.spinTiles(),
        new SequentialAnimation(
          new DelayAnimation(AnimationTimings.QUEUE_INITIAL_FILL_DELAY),
          this.queue.createInitialFillAnimation())))
  }

  private getNextBlock(): Block {
    return this.queue.front()!
  }

  private canPlaceNextBlockAt(pos: Vec2): boolean {
    return this.board.canPlaceAt(this.getNextBlock().polyomino, pos)
  }

  private placementErrors(pos: Vec2): Vec2[] {
    return this.board.placementErrors(this.getNextBlock().polyomino, pos)
  }

  private containsTile(pos: Vec2): boolean {
    return this.board.containsTile(pos)
  }

  placeBlock(pos: Vec2) {
    this.timeline.skipToEnd()

    const block = this.queue.front()
    if (!block) {
      return
    }

    this.moveStarting.emit()

    const boardAnimation = this.board.flipTiles(block.polyomino, pos)
    const queueAnimation = this.queue.placeBlock()

    this.queue.refill(this.board.tileSet())

    const score = block.getScore()
    const scoreAnimation = this.setScore(this.score + score)
    const movesAnimation = this.setMoves(this.moves + 1)

    const scoreBubble = ScoreBubble.forBlock(score, block.polyomino.getOrder(), pos.add(block.polyomino.getBoundingBoxCenter()))

    this.runOnTimeline(
      new ParallelAnimation(
        boardAnimation,
        queueAnimation,
        scoreBubble.createPopAnimation(this.board),
        scoreAnimation,
        movesAnimation))

    const bonus = this.checkBonus(this.board)
    if (bonus) {
      const scoreBubble = ScoreBubble.forBonus(bonus.score, bonus.type)
      const scoreAnimation = this.setScore(this.score + bonus.score)
      this.runOnTimeline(
        new ParallelAnimation(
          scoreBubble.createPopAnimation(this.board),
          new SoundAnimation(Sounds.BONUS),
          scoreAnimation))
    }

    this.checkGameOver()

    this.moveEnded.emit()
  }

  toggleStash() {
    if (this.stash.isEmpty()) {
      this.stashBlock()
    } else {
      this.unstashBlock()
    }
    this.checkGameOver()
  }

  debugReplace(index: number, order: number) {
    this.queue.debugReplace(index, this.board.tileSet(), order)
    this.checkGameOver()
  }

  private stashBlock() {
    this.timeline.skipToEnd()

    const [block, queueAnimation] = this.queue.stashBlock()
    const stashAnimation = this.stash.putBlock(block)
    this.queue.refill(this.board.tileSet())

    this.runOnTimeline(
      new ParallelAnimation(
        queueAnimation,
        stashAnimation,
        new SoundAnimation(Sounds.STASH)))
  }

  private unstashBlock() {
    this.timeline.skipToEnd()

    const block = this.stash.takeBlock()
    const queueAnimation = this.queue.unstashBlock(block)

    this.runOnTimeline(
      new ParallelAnimation(
        queueAnimation,
        new SoundAnimation(Sounds.UNSTASH)))
  }

  private setScore(score: number): Animation {
    this.score = score
    return new CallAnimation(() => this.scoreView.setText(`${score}`))
  }

  private setMoves(moves: number): Animation {
    this.moves = moves
    return new CallAnimation(() => this.movesView.setText(`${moves}`))
  }

  private checkBonus(board: Board): { type: BonusType, score: number } | null {
    const color = board.getUniformColor()
    if (color !== null) {
      return {
        type: color == TileColor.BLACK ? BonusType.BLACKOUT : BonusType.WHITEOUT,
        score: GameConstants.UNIFORM_COLOR_BONUS,
      }
    }
    return null
  }

  private checkGameOver() {
    const frontBlock = this.queue.front()
    if (!frontBlock) {
      return
    }

    if (!this.board.canPlaceAnywhere(frontBlock.polyomino) &&
        !this.stash.isEmpty() &&
        !this.board.canPlaceAnywhere(this.stash.getBlock()!.polyomino)) {
      this.clearHintTimeout()
      if (!this.gameOverScreen) {
        this.showGameOver()
      }
    } else {
      this.startHintTimeout()
    }
  }

  private showGameOver() {
    this.runOnTimeline(
      new ParallelAnimation(
        this.queue.showGameOver(3.0),
        this.board.showGameOver(3.0),
        new SequentialAnimation(
          new DelayAnimation(2.0),
          new ParallelAnimation(
            new SoundAnimation(Sounds.GAME_OVER),
            new CallAnimation(() => {
              this.gameOverScreen = new GameOverScreen()
              this.gameOverScreen.continueClicked.on(() => {
                this.finished.emit()
              })
              this.center.addChild(this.gameOverScreen)
            })))))
  }

  private startHintTimeout() {
    this.clearHintTimeout()
    const interval = InteractionConstants.INITIAL_HINT_INTERVAL
      * Math.pow(InteractionConstants.HINT_INTERVAL_GROWTH_PER_MOVE, this.moves)
    this.hintTimeoutId = window.setTimeout(this.showHint.bind(this), 1000 * interval)
  }

  private clearHintTimeout() {
    if (this.hintTimeoutId !== null) {
      window.clearTimeout(this.hintTimeoutId)
      this.hintTimeoutId = null
    }
  }

  private showHint() {
    const nextBlock = this.queue.front()
    if (!nextBlock) {
      return
    }

    const positions = this.board.placeablePositions(nextBlock.polyomino)
    let pos
    let parent
    if (positions.length == 0) {
      parent = this.stash
      pos = StashRenderingConstants.STASH_POSITION
    } else {
      parent = this.board
      pos = GLOBAL_RANDOM.arrayElement(positions).add(new Vec2(0.5, 0.5))
    }

    const hint = new Hint(pos)
    parent.addChild(hint)
    this.runOnTimeline(new SequentialAnimation(
      hint.createAnimation(),
      new CallAnimation(() => this.startHintTimeout())))
  }

  private runOnTimeline(animation: Animation) {
    this.timeline.push(animation)
  }

  private onMouseDown(e: MouseEvent) {
    if (e.button == 0) {
      e.preventDefault()
      window.addEventListener('mousemove', this.mouseMove)
      window.addEventListener('mouseup', this.mouseUp)
      this.pointerDown(this.board.posFromEvent(e))
    }
  }

  private onMouseMove(e: MouseEvent) {
    if (e.button == 0) {
      e.preventDefault()
      this.pointerMove(this.board.posFromEvent(e))
    }
  }

  private onMouseUp(e: MouseEvent) {
    if (e.button == 0) {
      window.removeEventListener('mousemove', this.mouseMove)
      window.removeEventListener('mouseup', this.mouseUp)
      this.pointerUp(this.board.posFromEvent(e))
    }
  }

  private onTouchStart(e: TouchEvent) {
    if (this.trackedTouchId == null) {
      e.preventDefault()
      const touch = e.changedTouches[0]
      this.trackedTouchId = touch.identifier
      window.addEventListener('touchmove', this.touchMove, {passive: false})
      window.addEventListener('touchend', this.touchEnd, {passive: false})
      window.addEventListener('touchcancel', this.touchCancel, {passive: false})
      this.pointerDown(this.board.posFromEvent(touch))
    }
  }

  private onTouchMove(e: TouchEvent) {
    for (let i = 0; i < e.changedTouches.length; i++) {
      const touch = e.changedTouches[i]
      if (touch.identifier == this.trackedTouchId) {
        e.preventDefault()
        this.pointerMove(this.board.posFromEvent(touch))
        break
      }
    }
  }

  private onTouchEnd(e: TouchEvent) {
    for (let i = 0; i < e.changedTouches.length; i++) {
      const touch = e.changedTouches[i]
      if (touch.identifier == this.trackedTouchId) {
        e.preventDefault()
        this.trackedTouchId = null
        window.removeEventListener('touchmove', this.touchMove)
        window.removeEventListener('touchend', this.touchEnd)
        window.removeEventListener('touchcancel', this.touchCancel)
        this.pointerUp(this.board.posFromEvent(touch))
        break
      }
    }
  }

  private onTouchCancel(e: TouchEvent) {
    for (let i = 0; i < e.changedTouches.length; i++) {
      const touch = e.changedTouches[i]
      if (touch.identifier == this.trackedTouchId) {
        e.preventDefault()
        this.trackedTouchId = null
        window.removeEventListener('touchmove', this.touchMove)
        window.removeEventListener('touchend', this.touchEnd)
        window.removeEventListener('touchcancel', this.touchCancel)
        this.pointerCancel()
        break
      }
    }
  }

  private pointerDown(pos: Vec2) {
    this.createGhost()
    this.updateGhost(pos, true)
    Sounds.TOUCH_DOWN.play({
      volume: 0.5,
      pitchRandomization: 0.1,
    })
  }

  private pointerMove(pos: Vec2) {
    this.updateGhost(pos)
  }

  private pointerUp(pos: Vec2) {
    if (this.ghost) {
      const polyomino = this.ghost.polyomino
      const now = Date.now()
      // Protect against accidental double clicks
      if (this.lastMoveTime == null || now - this.lastMoveTime >= InteractionConstants.MIN_MOVE_TIME * 1000) {
        if (this.canPlaceNextBlockAt(pos)) {
          this.placeBlock(pos)
          this.lastMoveTime = now
        } else {
          Sounds.ERROR.play({ volume: 0.5 })
        }
      }
      this.destroyGhost()
    }
  }

  private pointerCancel() {
    if (this.ghost) {
      this.destroyGhost()
    }
  }

  private createGhost() {
    if (this.ghost) {
      this.destroyGhost()
    }
    const block = this.getNextBlock()
    if (!block) {
      return
    }
    this.ghost = new Ghost(block.polyomino)
    this.board.addChild(this.ghost)
  }

  private updateGhost(pos: Vec2, force?: boolean) {
    if (!this.ghost) {
      return
    }
    if (!this.ghost.getPosition().equals(pos) || force) {
      this.ghost.setGhostPosition(pos)
      this.ghost.setGhostVisible(this.containsTile(pos))
      this.ghost.setErrors(this.placementErrors(pos))
    }
  }

  private destroyGhost() {
    if (!this.ghost) {
      return
    }
    this.ghost.fadeOut()
    this.ghost = null
  }

  private onDebugKeyDown(e: KeyboardEvent) {
    switch (e.keyCode) {
      case 71: // G
        this.showGameOver()
        break
      case 49:
      case 50:
      case 51:
      case 52:
      case 53:
      case 54:
      case 55:
      case 56:
      case 57:
        this.debugReplace(e.shiftKey ? 1 : 0, e.keyCode - 48)
        break
      case 81: // Q
        this.addChild(new GeneratorDisplay())
        break
      default:
        return // Do not break, do not call preventDefault()
    }
    e.preventDefault()
  }
}
