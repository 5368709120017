import '../scss/style.scss'

import { Elt, ANIMATION_MANAGER, ParallelAnimation, SoundAnimation } from './render'
import { Game, GameState, GameConstants, GameRenderingConstants } from './game'
import { Menu, MenuChoice, ScreenFlash, Button, LoadingScreen } from './ui'
import { Store, Settings } from './storage'
import { Vec2 } from './math'
import { SoundEffect, Sounds } from './audio'

class Main extends Elt {
  private readonly store = new Store()

  private readonly undoButton: Button
  private readonly menuButton: Button

  private game: Game | null = null
  private undoStack: GameState[] | null = null
  private menu: Menu | null = null

  constructor() {
    super('#main')
    this.addClass('main')

    this.undoButton = new Button().addClass('hud-button disabled')
        .addChild(new Elt('span').addClass('icon-undo2'))
    this.undoButton.clicked.on(this.onUndoButtonClicked.bind(this))
    this.addChild(new Elt().addClass('infobox top left')
        .addChild(this.undoButton))

    this.menuButton = new Button().addClass('hud-button')
        .addChild(new Elt('span').addClass('icon-menu'))
    this.menuButton.clicked.on(this.onMenuButtonClicked.bind(this))
    this.addChild(new Elt().addClass('infobox top right')
        .addChild(this.menuButton))

    this.store.settingsChanged.on(this.applySettings.bind(this))
    this.store.loadSettings()

    window.addEventListener('resize', this.onResize.bind(this))
    this.onResize()

    this.startGame(this.store.loadGameState())
    this.undoStack = []
    this.updateUndo()
  }

  private applySettings(settings: Settings) {
    SoundEffect.enabled = settings.soundEnabled
  }

  private startNewGame() {
    this.store.clearGameState()
    this.startGame(null)
    this.undoStack = []
    this.updateUndo()
  }

  private startGame(state: GameState | null) {
    this.endGame()

    if (state) {
      this.game = Game.fromState(state)
    } else {
      this.game = Game.create(0)
    }
    this.addChild(this.game)

    this.game.moveStarting.on(this.pushUndoState.bind(this))
    this.game.moveEnded.on(this.saveGame.bind(this))
    this.game.finished.on(this.onGameFinished.bind(this))
  }

  private endGame() {
    if (this.game) {
      this.game.removeFromParent()
      this.game = null
    }
  }

  private saveGame() {
    if (this.game) {
      this.store.saveGameState(this.game.toState())
    }
  }

  private pushUndoState() {
    if (this.game && this.undoStack) {
      this.undoStack.push(this.game.toState())
      while (this.undoStack.length > GameConstants.MAX_UNDOS) {
        this.undoStack.shift()
      }
      this.updateUndo()
    }
  }

  private onResize() {
    const windowSizePx = new Vec2(window.innerWidth, window.innerHeight)
    const gameSizeRem = GameRenderingConstants.GAME_SIZE
    const pxPerRem = Math.min(windowSizePx.x / gameSizeRem.x, windowSizePx.y / gameSizeRem.y)
    document.documentElement.style.fontSize = `${pxPerRem}px`
    let size
    if (windowSizePx.x / windowSizePx.y > GameRenderingConstants.MAX_ASPECT_RATIO) {
      size = new Vec2(gameSizeRem.y * GameRenderingConstants.MAX_ASPECT_RATIO, gameSizeRem.y)
    } else {
      size = windowSizePx.scl(1 / pxPerRem)
    }
    this.setSize(size)
  }

  private onUndoButtonClicked() {
    if (this.game && this.undoStack) {
      const state = this.undoStack.pop()
      if (!state) {
        return
      }

      const flash = new ScreenFlash()
      this.addChild(flash)
      ANIMATION_MANAGER.play(new ParallelAnimation(
            flash.createAnimation(),
            new SoundAnimation(Sounds.UNDO)))

      this.startGame(state)
      this.saveGame()
      this.updateUndo()
    }
  }

  private updateUndo() {
    this.undoButton.setVisible(!!this.game)
    this.undoButton.setDisabled(!this.undoStack || this.undoStack.length == 0)
  }

  private onMenuButtonClicked() {
    if (!this.menu) {
      this.menu = new Menu(this.store)
      this.menu.choiceMade.on(this.onMenuChoiceMade.bind(this))
      this.addChild(this.menu)
    }
  }

  private onMenuChoiceMade(e: { choice: MenuChoice}) {
    switch (e.choice) {
      case MenuChoice.NEW_GAME:
        this.startNewGame()
        break
      case MenuChoice.HIGHSCORES:
        // TODO
        break
      case MenuChoice.BACK:
        break
    }
    // Removing is done by the menu itself.
    this.menu = null
  }

  private onGameFinished() {
    // TODO show highscores instead
    this.startNewGame()
  }
}

window.addEventListener('load', () => {
  const loadingScreen = new LoadingScreen()
  loadingScreen.loaded.on(() => {
    loadingScreen.fadeOut()
    new Main()
  })
})
