import { debugEnabled } from '../debug'

export interface Platform {
  needsInteractionBeforeSound(): boolean
}

class BrowserPlatform implements Platform {
  needsInteractionBeforeSound(): boolean {
    return true
  }
}

export const CURRENT_PLATFORM: Platform = new BrowserPlatform()
