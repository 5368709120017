export interface EventReceiver<T> {
  (e: T): void
}

export class EventSender<T> {
  private readonly receivers: EventReceiver<T>[] = []

  on(receiver: EventReceiver<T>) {
    if (this.receivers.indexOf(receiver) < 0) {
      this.receivers.push(receiver)
    }
  }

  off(receiver: EventReceiver<T>) {
    const idx = this.receivers.indexOf(receiver)
    if (idx >= 0) {
      this.receivers.splice(idx, 1)
    }
  }

  emit(e: T) {
    for (const receiver of this.receivers.slice(0)) {
      receiver(e)
    }
  }
}
