import { ParallelAnimation, Animation, Easing, Tween, Elt } from '../render'
import { Tile } from './tile'
import { Polyomino } from './polyomino'
import { BlockState } from './state'
import { Vec2, square } from '../math'

/**
 * A Block is a polyomino with an identity, i.e. two Blocks may have the same
 * shape but still be different blocks.
 */
export class Block extends Elt {
  public readonly polyomino: Polyomino

  private constructor(polyomino: Polyomino) {
    super()
    this.polyomino = polyomino

    this.addClass('block').setAnimated(true)

    const offset = polyomino.getBoundingBoxCenter().neg()
    for (const pos of polyomino.tiles) {
      const tile = new BlockTile(offset.add(pos), polyomino.getOrder())
      this.addChild(tile)
    }

    const handleElt = new Elt()
      .addClass('handle')
      .setPosition(offset.add(new Vec2(0.5, 0.5)))
    this.addChild(handleElt)
  }

  static create(polyomino: Polyomino): Block {
    return new Block(polyomino)
  }

  static fromState(state: BlockState): Block {
    return new Block(new Polyomino(state.map(Vec2.fromArray)))
  }

  toState(): BlockState {
    return this.polyomino.tiles.map((v) => v.toArray())
  }

  getScore(): number {
    return square(this.polyomino.getOrder())
  }

  createAnimation(translation: Vec2, scale: number, duration: number): Animation {
    const animation = new ParallelAnimation()
    animation.push(new Tween({
      object: this,
      getter: this.getTranslation,
      setter: this.setTranslation,
      to: translation,
      duration: duration,
      easing: Easing.QUAD_IN_OUT,
    }))
    animation.push(new Tween({
      object: this,
      getter: this.getScale,
      setter: this.setScale,
      to: scale,
      duration: duration,
      easing: Easing.QUAD_IN_OUT,
    }))
    return animation
  }
}

class BlockTile extends Tile {
  constructor(pos: Vec2, order: number) {
    super(pos, 'block-tile', `order-${order}`)
  }
}
