import { Elt } from '../render'
import { Polyomino } from '../game'
import { Tile } from './tile'
import { ANIMATION_MANAGER, Animation, SequentialAnimation, CallAnimation, Tween, Easing } from '../render'
import { Vec2 } from '../math'
import { AnimationTimings } from './constants'

export class Ghost extends Elt {
  public readonly polyomino: Polyomino

  private pos: Vec2 = Vec2.ZERO
  private readonly errors: Elt[] = []
  private outAnimation: Animation | null = null

  constructor(polyomino: Polyomino) {
    super()
    this.setAnimated(true)
    this.addClass('ghost')

    this.polyomino = polyomino

    for (const pos of polyomino.tiles) {
      const tile = new GhostTile(pos, polyomino.getOrder())
      this.addChild(tile)
    }

    const handleElt = new Elt()
      .addClass('handle')
      .setPosition(new Vec2(0.5, 0.5))
    this.addChild(handleElt)
  }

  getPosition(): Vec2 {
    return this.pos
  }

  setGhostPosition(pos: Vec2) {
    this.pos = pos
    this.setTranslation(pos)
  }

  setGhostVisible(visible: boolean) {
    this.setOpacity(visible ? 1 : 0)
  }

  setErrors(errors: Array<Vec2>) {
    // Always re-create all elements because CSS animations get out of sync
    // otherwise.
    for (let i = 0; i < this.errors.length; i++) {
      this.errors[i].removeFromParent()
    }
    this.errors.length = 0
    for (let i = 0; i < errors.length; i++) {
      const elt = new Elt().addClass('error blinking')
      elt.setSize(new Vec2(1, 1))
      elt.setPosition(errors[i])
      this.errors.push(elt)
      this.addChild(elt)
    }
  }

  hasErrors() {
    return this.errors.length > 0
  }

  fadeOut() {
    if (this.outAnimation) {
      return
    }
    this.outAnimation = new SequentialAnimation(
      new Tween({
        object: this,
        getter: this.getOpacity,
        setter: this.setOpacity,
        to: 0.0,
        duration: this.hasErrors() ?
          AnimationTimings.GHOST_FADE_OUT_ERROR_DURATION :
          AnimationTimings.GHOST_FADE_OUT_SUCCESS_DURATION,
        easing: Easing.QUAD_IN,
      }),
      new CallAnimation(() => this.removeFromParent()))
    ANIMATION_MANAGER.play(this.outAnimation)
  }
}

class GhostTile extends Tile {
  constructor(pos: Vec2, order: number) {
    super(pos, 'ghost-tile', `order-${order}`)
  }
}
