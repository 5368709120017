import { GameConstants, BlockGenerator, BlockGeneratorOptions, Tile } from '../game'
import { Elt } from '../render'
import { GridSet } from '../math'
import { Random } from '../math'
import { Vec2 } from '../math'

import '../../scss/debug.scss'

export function debugEnabled(): boolean {
  return window.location.hash == '#debug'
}

export class GeneratorDisplay extends Elt {
  private readonly controls: Elt = new Elt()
  private readonly chart: Elt = new Elt()
  private readonly output: Elt = new Elt('pre')

  private readonly options: BlockGeneratorOptions = { ...GameConstants.BLOCK_GENERATOR_OPTIONS }

  constructor() {
    super()
    this.addClass('fullscreen generator-display')

    this.addControl('nextSetSize', 1, 9, 1)
    this.addControl('meanInitial', 0, 4, 0.1)
    this.addControl('meanDivisor', 10, 1000, 10)
    this.addControl('meanExponent', 0.1, 3, 0.1)
    this.addControl('stdDevInitial', 0, 3, 0.1)
    this.addControl('stdDevDivisor', 10, 1000, 10)
    this.addControl('stdDevExponent', 0.1, 3, 0.1)

    this.addChild(this.controls)
    this.addChild(this.chart)
    this.addChild(this.output)

    this.update()
  }

  private addControl(field: string, min: number, max: number, step: number) {
    const val = new Elt('span').addClass('generator-value')
      .setText(`${(this.options as any)[field]}`)
    const control = new Elt().addClass('generator-control-wrapper')
      .addChild(new Elt('label').addClass('generator-control-label')
          .setText(field))
      .addChild(new Elt('input').addClass('generator-control')
          .setAttribute('type', 'range')
          .setAttribute('min', `${min}`)
          .setAttribute('max', `${max}`)
          .setAttribute('step', `${step}`)
          .setAttribute('value', (this.options as any)[field])
          .addEventListener('change', (e: Event) => {
            e.preventDefault()
            val.setText((e.target as HTMLInputElement).value)
            this.onChange(field, parseFloat((e.target as HTMLInputElement).value))
          }))
      .addChild(val)
    this.controls.addChild(control)
  }

  private onChange(field: string, value: number) {
    (this.options as any)[field] = value
    this.update()
  }

  private update() {
    this.output.setText(JSON.stringify(this.options))

    this.chart.clear()
    const generator = BlockGenerator.create(0, this.options)
    for (let i = 0; i < 300; i++) {
      const order = generator.next(GameConstants.BOARD_TILES).getOrder()
      const bar = new Elt().addClass('generator-display-bar')
      for (let j = 9; j >= 1; j--) {
        bar.addChild(new Elt()
            .addClass('tile block-tile')
            .addChild(new Elt().addClass(`tile-inner order-${order}`))
            .setStyle('position', 'relative')
            .setStyle('visibility', j <= order ? 'visible' : 'hidden'))
      }
      bar.addChild(new Elt().addClass('generator-display-bar-label').setText(i % 10 == 0 ? `${i}` : ''))
      this.chart.addChild(bar)
    }
  }
}
