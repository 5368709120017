import { StashRenderingConstants, AnimationTimings } from './constants'
import { Animation, Easing, Tween, Elt } from '../render'
import { EventSender } from '../utils'
import { Block } from './block'
import { StashState } from './state'

export class Stash extends Elt {

  public readonly clicked = new EventSender<void>()

  private block: Block | null = null

  private constructor(block: Block | null) {
    super()
    const frame = new Elt().addClass('stash')
      .setAnimated(true) // Overlaid on top of animated blocks, so should be in a separate layer.
      .setSize(StashRenderingConstants.STASH_SIZE)
      .setPosition(StashRenderingConstants.STASH_POSITION.sub(StashRenderingConstants.STASH_SIZE.scl(0.5)))
      .addEventListener('click', (e: Event) => { e.preventDefault(); this.clicked.emit(); })
    this.addChild(frame)

    this.block = block
    if (this.block) {
      this.addChild(this.block)
      this.block
        .setTranslation(StashRenderingConstants.STASH_POSITION)
        .setScale(StashRenderingConstants.STASH_SCALE)
    }
  }

  static create(): Stash {
    return new Stash(null)
  }

  static fromState(state: StashState): Stash {
    const block = state ? Block.fromState(state) : null
    return new Stash(block)
  }

  toState(): StashState {
    return this.block ? this.block.toState() : null
  }

  isEmpty(): boolean {
    return !this.block
  }

  getBlock(): Block | null {
    return this.block
  }

  putBlock(block: Block): Animation {
    if (this.block) {
      throw new Error('Attempted to put into a full stash')
    }
    this.block = block
    this.addChild(this.block)
    return this.block.createAnimation(StashRenderingConstants.STASH_POSITION, StashRenderingConstants.STASH_SCALE, AnimationTimings.STASH_DURATION)
  }

  takeBlock(): Block {
    if (!this.block) {
      throw new Error('Attempted to take from empty stash')
    }
    const block = this.block
    this.block = null
    block.removeFromParent()
    return block
  }
}
