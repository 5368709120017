import { Animation, Delta } from './animation'
import { Vec2 } from '../math'

export type TweenOptions<O, T> = {
  object: O,
  getter?: (this: O) => T,
  setter: (this: O, value: T) => void,
  from?: T,
  to: T,
  duration: number,
  easing?: EasingFunction,
}

export type EasingFunction = (x: number) => number

const PI = Math.PI
const HALF_PI = 0.5 * PI
export namespace Easing {
  export const LINEAR = (x: number) => x

  export const SINE_IN = (x: number) => 1 - Math.cos(x * HALF_PI)
  export const SINE_OUT = (x: number) => Math.sin(x * HALF_PI)
  export const SINE_IN_OUT = (x: number) => 0.5 - 0.5 * Math.cos(x * PI)

  export const QUAD_IN = (x: number) => x * x
  export const QUAD_OUT = (x: number) => 1 - (x - 1) * (x - 1)
  export const QUAD_IN_OUT = (x: number) => x < 0.5 ? 2 * x * x : 1 - 2 * (x - 1) * (x - 1)
}

interface Lerpable<T> {
  lerp(to: T, f: number): T
}

type LerpableOrNumber<T> = number | Lerpable<T>

function lerp<T extends LerpableOrNumber<T>>(a: T, b: T, f: number): T {
    if (typeof a == 'number') {
      return ((1 - f) * a + f * (b as number)) as T
    } else {
      return (a as Lerpable<T>).lerp(b as T, f)
    }
}

export class Tween<O, T extends LerpableOrNumber<T>> implements Animation {
  private readonly object: O
  private readonly setter: (this: O, value: T) => void
  private from: T | ((this: O) => T)
  private readonly to: T
  private readonly duration: number
  private readonly easing: EasingFunction

  private elapsed: number = 0

  constructor(options: TweenOptions<O, T>) {
    if ((typeof options.getter == 'undefined') == (typeof options.from == 'undefined')) {
      throw new Error('exactly one of getter and from must be defined')
    }
    this.object = options.object
    this.setter = options.setter
    this.from = options.getter || options.from!
    this.to = options.to
    this.duration = options.duration
    this.easing = options.easing || Easing.LINEAR
  }

  update(delta: Delta): boolean {
    this.elapsed += delta.getRemaining()
    delta.consumeAtMost(this.duration - this.elapsed)
    if (typeof this.from == 'function') {
      this.from = this.from.call(this.object)
    }
    const progress = Math.min(1, Math.max(0, this.elapsed / this.duration))
    const easedProgress = this.easing(progress)
    const value = lerp(this.from, this.to, easedProgress)
    this.setter.call(this.object, value)
    return progress >= 1
  }

  toString(): string {
    return `tween(${this.object}, ${this.from}, ${this.to}, ${this.duration})`
  }
}
