/**
 * An immutable 2-vector. Used for integers and doubles alike.
 */
export class Vec2 {
  public static ZERO: Vec2 = new Vec2(0, 0)

  public readonly x: number
  public readonly y: number

  constructor(x: number, y: number) {
    this.x = x
    this.y = y
  }

  static fromArray([x, y]: [number, number]) {
    return new Vec2(x, y)
  }

  toArray(): [number, number] {
    return [this.x, this.y]
  }

  add(v: Vec2): Vec2 {
    return new Vec2(this.x + v.x, this.y + v.y)
  }

  sub(v: Vec2): Vec2 {
    return new Vec2(this.x - v.x, this.y - v.y)
  }

  mul(v: Vec2): Vec2 {
    return new Vec2(this.x * v.x, this.y * v.y)
  }

  div(v: Vec2): Vec2 {
    if (v.x == 0 || v.y == 0) {
      throw new Error(`Division by zero: ${this.toString()} / ${v.toString()}`)
    }
    return new Vec2(this.x / v.x, this.y / v.y)
  }

  scl(f: number): Vec2 {
    return new Vec2(this.x * f, this.y * f)
  }

  neg(): Vec2 {
    return new Vec2(-this.x, -this.y)
  }

  round(): Vec2 {
    return new Vec2(Math.round(this.x), Math.round(this.y))
  }

  floor(): Vec2 {
    return new Vec2(Math.floor(this.x), Math.floor(this.y))
  }

  ceil(): Vec2 {
    return new Vec2(Math.ceil(this.x), Math.ceil(this.y))
  }

  length(): number {
    return Math.sqrt(this.x * this.x + this.y * this.y)
  }

  distance(v: Vec2): number {
    return this.sub(v).length()
  }

  rotated(radians: number): Vec2 {
    const cos = Math.cos(radians)
    const sin = Math.sin(radians)
    return new Vec2(this.x * cos - this.y * sin, this.x * sin + this.y * cos)
  }

  lerp(to: Vec2, f: number): Vec2 {
    const g = 1 - f
    return new Vec2(g * this.x + f * to.x, g * this.y + f * to.y)
  }

  toString(): string {
    return `${this.x}, ${this.y}`
  }

  equals(v: Vec2): boolean {
    return this.x == v.x && this.y == v.y
  }

  neighbors(): Array<Vec2> {
    return [
      new Vec2(this.x, this.y - 1),
      new Vec2(this.x + 1, this.y),
      new Vec2(this.x, this.y + 1),
      new Vec2(this.x - 1, this.y),
    ]
  }
}
