import { Elt } from '../render'
import { Button, ToggleButton } from './button'
import { Store } from '../storage'
import { EventSender } from '../utils'

export enum MenuChoice {
  NEW_GAME,
  HIGHSCORES,
  BACK,
}

export class Menu extends Elt {
  public readonly choiceMade = new EventSender<{ choice: MenuChoice }>()

  private readonly store: Store

  private readonly buttons: Button[] = []
  private readonly newGameButton: Button
  private readonly highscoresButton: Button
  private readonly soundButton: ToggleButton
  private readonly backButton: Button

  constructor(store: Store) {
    super()
    this.store = store
    this.addClass('menu fullscreen visible flip-in')

    const background = new Elt().addClass('menu-background')
    background.addEventListener('click', (e: Event) => this.onBackClicked())
    this.addChild(background)

    this.newGameButton = this.addButton('New game', this.onNewGameClicked)
    this.soundButton = this.addToggleButton('Sound', store.currentSettings().soundEnabled, this.onSoundClicked)
    this.highscoresButton = this.addButton('Highscores', this.onHighscoresClicked)
    this.backButton = this.addButton('Back to game', this.onBackClicked)
  }

  private addButton(text: string, onClick: (this: Menu) => void): Button {
    const button = new Button(text)
      .addClass(`tile-button menu-tile-button ${this.nextButtonColor()}`)
    button.clicked.on(() => { onClick.call(this); })
    this.buttons.push(button)
    this.addChild(button)
    return button
  }

  private addToggleButton(text: string, checked: boolean, onClick: (this: Menu, checked: boolean) => void): ToggleButton {
    const button = new ToggleButton(text, checked)
      .addClass(`tile-button menu-tile-button ${this.nextButtonColor()}`)
    button.toggled.on((checked) => { onClick.call(this, checked); })
    this.buttons.push(button)
    this.addChild(button)
    return button
  }

  private nextButtonColor(): 'white' | 'black' {
    return this.buttons.length % 2 == 0 ? 'white' : 'black'
  }

  private onNewGameClicked() {
    this.disableFlip(this.newGameButton)
    this.choiceMade.emit({ choice: MenuChoice.NEW_GAME })
    this.close()
  }

  private onSoundClicked(checked: boolean) {
    this.store.updateSettings({ soundEnabled: checked })
  }

  private onHighscoresClicked() {
    this.disableFlip(this.highscoresButton)
    this.choiceMade.emit({ choice: MenuChoice.HIGHSCORES })
    this.close()
  }

  private onBackClicked() {
    this.disableFlip(this.backButton)
    this.choiceMade.emit({choice: MenuChoice.BACK })
    this.close()
  }

  private disableFlip(button: Button) {
    button.addClass('no-flip')
  }

  private close() {
    // Prevent clicking another button during the out transition.
    this.addEventListener('click', function(e: Event) { e.stopPropagation(); }, {capture: true})
    this.addEventListener('transitionend', () => this.removeFromParent())
    this.removeClass('visible flip-in')
  }
}
