import { Vec2 } from './vector'

/**
 * A sparse, potentially infinite grid where each cell may hold a value of type
 * T.
 */
export class GridMap<T> {
  // The implementation uses a map of maps, because this is JavaScript so we
  // can't have nice things like a map keyed on Vec2. (Even ES6's Map class
  // wouldn't work, because it uses value equality only.)
  private rows: {[index: number]: {[index: number]: T}} = {}

  get(pos: Vec2): T | undefined {
    const row = this.rows[pos.y]
    return row && row[pos.x]
  }

  set(pos: Vec2, val: T) {
    if (!this.rows[pos.y]) {
      this.rows[pos.y] = {}
    }
    this.rows[pos.y][pos.x] = val
  }

  contains(pos: Vec2): boolean {
    return this.get(pos) !== undefined
  }

  remove(pos: Vec2) {
    const row = this.rows[pos.y]
    if (row) {
      delete row[pos.x]
      // Note: we don't delete the row if it's now empty.
    }
  }

  clear() {
    this.rows = {}
  }

  keys(): Array<Vec2> {
    const keys = []
    for (const y of Object.keys(this.rows)) {
      const yi = parseInt(y)
      for (const x of Object.keys(this.rows[yi])) {
        const xi = parseInt(x)
        keys.push(new Vec2(xi, yi))
      }
    }
    return keys
  }

  values(): Array<T> {
    const values = []
    for (const y of Object.keys(this.rows)) {
      const row = this.rows[parseInt(y)]
      for (const x of Object.keys(row)) {
        values.push(row[parseInt(x)])
      }
    }
    return values
  }

  clone(): GridMap<T> {
    const clone = new GridMap<T>()
    for (const key of this.keys()) {
      clone.set(key, this.get(key)!)
    }
    return clone
  }
}

/**
 * A set of grid coordinates.
 */
export class GridSet {
  private grid: GridMap<boolean> = new GridMap<boolean>()

  constructor(initial?: Array<Vec2>) {
    if (initial) {
      for (const pos of initial) {
        this.add(pos)
      }
    }
  }

  add(pos: Vec2) {
    this.grid.set(pos, true)
  }

  remove(pos: Vec2) {
    this.grid.remove(pos)
  }

  contains(pos: Vec2): boolean {
    return this.grid.get(pos) == true
  }

  keys(): Array<Vec2> {
    return this.grid.keys()
  }

  clone(): GridSet {
    const clone = new GridSet()
    clone.grid = this.grid.clone()
    return clone
  }
}
