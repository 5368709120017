import { EventSender } from '../utils'
import { GameState } from '../game'
import { Settings, defaultSettings } from './settings'

const GAME_STATE_KEY = 'gameState'
const SETTINGS_KEY = 'settings'

export class Store {
  public readonly settingsChanged = new EventSender<Settings>()

  private localStorage: Storage
  private settings: Settings = defaultSettings()

  constructor() {
    this.localStorage = window.localStorage
  }

  saveGameState(state: GameState) {
    this.save(GAME_STATE_KEY, state)
  }

  loadGameState(): GameState | null {
    return this.load<GameState>(GAME_STATE_KEY)
  }

  clearGameState() {
    this.clear(GAME_STATE_KEY)
  }

  currentSettings(): Readonly<Settings> {
    return this.settings
  }

  loadSettings() {
    this.settings = this.load<Settings>(SETTINGS_KEY) || defaultSettings()
    this.settingsChanged.emit(this.settings)
  }

  updateSettings(newSettings: Partial<Settings>) {
    this.settings = { ...this.settings, ...newSettings }
    this.save(SETTINGS_KEY, this.settings)
    this.settingsChanged.emit(this.settings)
  }

  private save(key: string, value: any) {
    this.localStorage.setItem(key, JSON.stringify(value))
  }

  private load<T>(key: string): T | null {
    const json = this.localStorage.getItem(key)
    if (!json) {
      return null
    }
    try {
      return JSON.parse(json)
    } catch (ex) {
      console.warn('Error parsing JSON:', ex)
      return null
    }
  }

  private clear(key: string) {
    this.localStorage.removeItem(key)
  }
}
