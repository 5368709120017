import { GameConstants } from './constants'
import { GridSet } from '../math'
import { Vec2 } from '../math'

export class Polyomino {
  public readonly tiles: ReadonlyArray<Vec2>

  constructor(tiles: Vec2[]) {
    if (tiles.length == 0) {
      throw new Error(`Cannot construct an empty polyomino`)
    }
    tiles = tiles.slice(0)

    // Sort tiles in reading order to make shadows work out correctly.
    tiles.sort(function(a: Vec2, b: Vec2) {
      if (a.y == b.y) {
        return a.x - b.x
      } else {
        return a.y - b.y
      }
    })

    // For ergonomic reasons, put the handle (origin) as far down as it will
    // go, and then as far to the right. Conveniently, the last in reading
    // order.
    const handle = tiles[tiles.length - 1]
    for (let i = 0; i < tiles.length; i++) {
      tiles[i] = tiles[i].sub(handle)
    }

    this.tiles = tiles
  }

  static allOfOrder(order: number): Array<Polyomino> {
    if (BY_ORDER.length == 0) {
      generatePolyominoes()
    }
    return BY_ORDER[order]
  }

  getOrder(): number {
    return this.tiles.length
  }

  getBoundingBoxCenter(): Vec2 {
    let xMin = 0
    let xMax = 0
    let yMin = 0
    let yMax = 0
    for (const tile of this.tiles) {
      xMin = Math.min(xMin, tile.x)
      xMax = Math.max(xMax, tile.x)
      yMin = Math.min(yMin, tile.y)
      yMax = Math.max(yMax, tile.y)
    }
    return new Vec2((xMax + xMin) / 2 + 0.5, (yMax + yMin) / 2 + 0.5)
  }

  fitsIn(board: GridSet): boolean {
    for (const offset of board.keys()) {
      let fits = true
      for (const pos of this.tiles) {
        if (!board.contains(offset.add(pos))) {
          fits = false
          break
        }
      }
      if (fits) {
        return true
      }
    }
    return false
  }

  toString(): string {
    return this.tiles.join(' ')
  }

  getDescriptionHtml(): string {
    const order = this.getOrder()
    return [
      'zeromino', // ???
      '<span class="order-1-color">monomino</span>',
      '<span class="order-2-color">domino</span>',
      '<span class="order-3-color">tromino</span>',
      '<span class="order-4-color">tetromino</span>',
      '<span class="order-5-color">pentomino</span>',
      '<span class="order-6-color">hexomino</span>',
      '<span class="order-7-color">heptomino</span>',
      '<span class="order-8-color">octomino</span>',
      '<span class="order-9-color">nonomino</span>',
    ][order] || `${order}-omino`
  }
}

// https://en.wikipedia.org/wiki/Polyomino#Algorithms_for_enumeration_of_fixed_polyominoes
//
// The simplest implementation involves adding one square at a time. Beginning
// with an initial square, number the adjacent squares, clockwise from the top,
// 1, 2, 3, and 4. Now pick a number between 1 and 4, and add a square at that
// location. Number the unnumbered adjacent squares, starting with 5. Then,
// pick a number larger than the previously picked number, and add that square.
// Continue picking a number larger than the number of the current square,
// adding that square, and then numbering the new adjacent squares. When n
// squares have been created, an n-omino has been created.
//
// This method ensures that each fixed polyomino is counted exactly n times,
// once for each starting square. It can be optimized so that it counts each
// polyomino only once, rather than n times. Starting with the initial square,
// declare it to be the lower-left square of the polyomino. Simply do not
// number any square that is on a lower row, or left of the square on the same
// row.

const BY_ORDER: Array<Array<Polyomino>> = []

type Map<T> = {
  [index: string]: T
}

function generatePolyominoes() {
  for (let order = 0; order <= GameConstants.MAX_POLYOMINO_ORDER; order++) {
    BY_ORDER[order] = []
  }

  generateFixedPolyominoes([], new GridSet(), [Vec2.ZERO], new GridSet([Vec2.ZERO]), 0)
}

function generateFixedPolyominoes(tiles: Array<Vec2>, tilesSet: GridSet, candidates: Array<Vec2>, candidatesSet: GridSet, minPick: number) {
  const order = tiles.length
  if (order > 0) {
    BY_ORDER[order].push(new Polyomino(tiles))
  }

  if (order < GameConstants.MAX_POLYOMINO_ORDER) {
    for (let pick = minPick; pick < candidates.length; pick++) {
      const tile = candidates[pick]
      const newTiles = tiles.slice(0)
      const newTilesSet = tilesSet.clone()
      const newCandidates = candidates.slice(0)
      const newCandidatesSet = candidatesSet.clone()
      newTiles.push(tile)
      newTilesSet.add(tile)
      for (const neighbor of tile.neighbors()) {
        if (neighbor.y < 0 || (neighbor.y == 0 && neighbor.x < 0)) {
          continue
        }
        if (tilesSet.contains(neighbor)) {
          continue
        }
        if (candidatesSet.contains(neighbor)) {
          continue
        }
        newCandidatesSet.add(neighbor)
        newCandidates.push(neighbor)
      }
      generateFixedPolyominoes(newTiles, newTilesSet, newCandidates, newCandidatesSet, pick + 1)
    }
  }
}
