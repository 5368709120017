import { GridSet } from '../math'
import { Vec2 } from '../math'

export namespace GameConstants {
  export const QUEUE_LENGTH = 12
  export const BOARD_SIZE = new Vec2(5, 5)
  export const MAX_BLOCK_SIZE = BOARD_SIZE
  export const MAX_POLYOMINO_ORDER = 9
  export const MAX_UNDOS = 1
  export const UNIFORM_COLOR_BONUS = 100
  export const BOARD_TILES = (function() {
    const tiles = new GridSet()
    for (let y = 0; y < BOARD_SIZE.y; y++) {
      for (let x = 0; x < BOARD_SIZE.x; x++) {
        tiles.add(new Vec2(x, y))
      }
    }
    return tiles
  })()
  export const BLOCK_GENERATOR_OPTIONS = {
    nextSetSize: 3,
    meanInitial: 2.5,
    meanDivisor: 150,
    meanExponent: 0.5,
    stdDevInitial: 0.8,
    stdDevDivisor: 150,
    stdDevExponent: 0.5,
  }
}

// TODO remove prefixes from all this stuff

export namespace QueueRenderingConstants {
  export const QUEUE_LENGTH = GameConstants.QUEUE_LENGTH
  export const QUEUE_BLOCK_SCALE = 1/5.0
  export const QUEUE_NEXT_BLOCK_SCALE = 1/2.5
  export const QUEUE_BLOCK_SIZE = GameConstants.MAX_BLOCK_SIZE.scl(QUEUE_BLOCK_SCALE)
  export const QUEUE_NEXT_BLOCK_SIZE = GameConstants.MAX_BLOCK_SIZE.scl(QUEUE_NEXT_BLOCK_SCALE)
  export const QUEUE_MIDDLE_RADIUS =
    (0.5 * Math.max(GameConstants.MAX_BLOCK_SIZE.x, GameConstants.MAX_BLOCK_SIZE.y)
     + 0.5 * Math.max(QUEUE_BLOCK_SIZE.x, QUEUE_BLOCK_SIZE.y)
     + 0.05)
      / Math.cos(30 * Math.PI / 180)
}

export namespace StashRenderingConstants {
  export const STASH_SCALE = QueueRenderingConstants.QUEUE_BLOCK_SCALE
  export const STASH_SIZE = GameConstants.MAX_BLOCK_SIZE.scl(STASH_SCALE)
  export const STASH_POSITION = new Vec2(
      0,
      -QueueRenderingConstants.QUEUE_MIDDLE_RADIUS
      - 0.5 * QueueRenderingConstants.QUEUE_NEXT_BLOCK_SIZE.y
      - 0.4
      - 0.5 * STASH_SIZE.y)
}

export namespace GameRenderingConstants {
  const GAME_WIDTH = 2 * QueueRenderingConstants.QUEUE_MIDDLE_RADIUS + QueueRenderingConstants.QUEUE_BLOCK_SIZE.x
  const GAME_HEIGHT = 2 * (-StashRenderingConstants.STASH_POSITION.y + 0.5 * StashRenderingConstants.STASH_SIZE.y + 0.3)
  const GAME_PADDING = 0.2
  export const GAME_SIZE = new Vec2(GAME_WIDTH + 2 * GAME_PADDING, GAME_HEIGHT + 2 * GAME_PADDING)
  export const GAME_CENTER = new Vec2(GAME_PADDING + GAME_WIDTH / 2, GAME_PADDING + GAME_HEIGHT - GAME_WIDTH / 2)
  export const MAX_ASPECT_RATIO = 1.0
}

export namespace InteractionConstants {
  export const MIN_MOVE_TIME = 0.2
  export const INITIAL_HINT_INTERVAL = 5.0
  export const HINT_INTERVAL_GROWTH_PER_MOVE = 1.03
}

export namespace AnimationTimings {
  export const BOARD_RANDOMIZE_DELAY = 1.0
  export const BOARD_RANDOMIZE_DURATION = 2.5
  export const BOARD_RANDOMIZE_TILE_FLIPS = 3
  export const FLIP_DURATION = 0.2
  export const FLIP_WAVE_DELAY = 0.3 * FLIP_DURATION
  export const QUEUE_INITIAL_FILL_DELAY = 0.5
  export const QUEUE_INITIAL_FILL_DURATION = 3.0
  export const QUEUE_SCROLL_DURATION = 0.4
  export const SCORE_BUBBLE_DURATION = 1.0
  export const GHOST_FADE_OUT_SUCCESS_DURATION = 0.6
  export const GHOST_FADE_OUT_ERROR_DURATION = 1.0
  export const STASH_DURATION = QUEUE_SCROLL_DURATION
  export const UNDO_DURATION = 0.6
  export const BONUS_BUBBLE_DURATION = 4.5
}
