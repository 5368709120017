import { Elt } from '../render'
import { EventSender } from '../utils'

export class GameOverScreen extends Elt {

  public readonly continueClicked = new EventSender<void>()

  constructor() {
    super()
    this.addClass('game-over flip-in')
    this.addChild(new Elt().addClass('on-board')
        .addChild(new Elt().addClass('game-over-text').setText('Game Over')))
    this.addChild(new Elt().addClass('below-board')
        .addChild(new Elt('a')
            .addClass('button tile-button white continue-button')
            .setText('Continue')
            .addEventListener('click', (e: Event) => {
              e.preventDefault()
              this.continueClicked.emit()
            })))
  }
}
