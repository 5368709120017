import { Elt, ANIMATION_MANAGER, Tween, Easing, SequentialAnimation, CallAnimation } from '../render'
import { EventSender } from '../utils'
import { Sounds, SoundEffect } from '../audio'
import { debugEnabled } from '../debug'
import { CURRENT_PLATFORM } from '../platform'

export class LoadingScreen extends Elt {

  public readonly loaded = new EventSender<void>()

  private loadingCount: number = 0

  constructor() {
    super('#loading')

    for (const key in Sounds as any) {
      const sound = (Sounds as any)[key] as SoundEffect
      this.loadingCount++
      sound.load((error: string | null) => {
        if (error) {
          console.error(error)
        }
        this.loadingCount--
        if (this.loadingCount == 0) {
          this.doneLoading()
        }
      })
    }
  }

  private doneLoading() {
    if (!debugEnabled() && CURRENT_PLATFORM.needsInteractionBeforeSound()) {
      this.addClass('loading-done')
      new Elt('#click-text').setText(typeof document.ontouchstart != 'undefined' ? 'Tap to continue' : 'Click to continue')
      this.addEventListener('click', this.onClicked)
    } else {
      this.loaded.emit()
    }
  }
  
  private readonly onClicked = (e: Event) => {
    e.preventDefault()
    this.removeEventListener('click', this.onClicked)
    Sounds.BUTTON_CLICK.play()
    this.loaded.emit()
  }

  fadeOut() {
    ANIMATION_MANAGER.play(new SequentialAnimation(
      new Tween({
        object: this,
        setter: this.setOpacity,
        from: 1 as number,
        to: 0 as number,
        duration: 0.5,
        easing: Easing.SINE_IN_OUT,
      }),
      new CallAnimation(() => this.removeFromParent())))
  }
}
