import { SoundEffect, PlaybackOptions } from '../audio'

export class Delta {

  private remaining: number

  constructor(remaining: number) {
    this.remaining = remaining
  }

  getRemaining(): number {
    return this.remaining
  }

  consumeAtMost(time: number): number {
    if (time < this.remaining) {
      this.remaining -= time
      return time
    } else {
      const consumed = this.remaining
      this.remaining = 0
      return consumed
    }
  }

  isEmpty(): boolean {
    return this.remaining <= 0
  }
}

export interface Animation {
  /**
   * Returns true if the animation is done.
   */
  update(delta: Delta): boolean

  toString(): string
}

/**
 * An animation that does nothing and takes no time.
 */
export class NullAnimation implements Animation {
  update(delta: Delta): boolean {
    return true
  }

  toString(): string {
    return 'null()'
  }
}

/**
 * An animation that consumes a fixed amount of time.
 */
export class DelayAnimation implements Animation {
  private delay: number
  private remainingTime: number

  constructor(delay: number) {
    this.delay = delay
    this.remainingTime = delay
  }

  update(delta: Delta): boolean {
    this.remainingTime -= delta.consumeAtMost(this.remainingTime)
    return !delta.isEmpty()
  }

  toString(): string {
    return `delay(${this.delay})`
  }
}

/**
 * An animation that simply calls a function once, and takes no time.
 */
export class CallAnimation implements Animation {
  private readonly func: () => void

  constructor(func: () => void) {
    this.func = func
  }

  update(delta: Delta): boolean {
    this.func()
    return true
  }

  toString(): string {
    return `call(${this.func})`
  }
}

/**
 * An animation that plays a sound effect, but does not wait for it to
 * complete.
 */
export class SoundAnimation implements Animation {
  private readonly soundEffect: SoundEffect
  private readonly options: PlaybackOptions

  constructor(soundEffect: SoundEffect, options: PlaybackOptions = {}) {
    this.soundEffect = soundEffect
    this.options = options
  }

  update(delta: Delta): boolean {
    this.soundEffect.play(this.options)
    return true
  }

  toString(): string {
    return `sound(${this.soundEffect.name})`
  }
}

/**
 * An animation that consists of multiple sub-animations.
 */
export abstract class CompositeAnimation implements Animation {
  protected animations: Animation[] = []

  constructor(...animations: Animation[]) {
    this.push(...animations)
  }

  push(...animations: Animation[]) {
    for (const animation of animations) {
      if (this.animations.indexOf(animation) < 0) {
        this.animations.push(animation)
      }
    }
  }

  clear() {
    this.animations.splice(0)
  }

  isDone(): boolean {
    return this.animations.length == 0
  }

  abstract update(delta: Delta): boolean

  skipToEnd() {
    this.update(new Delta(1000))
  }

  toString(): string {
    return `${this.baseName()}(\n${this.animations.map((a) => CompositeAnimation.indentLines(a.toString())).join(',\n')})`
  }

  protected abstract baseName(): string

  private static indentLines(lines: string, indentation: string = '  ') {
    return lines.split('\n').map((line) => indentation + line).join('\n')
  }
}

/**
 * An animation that plays sub-animations in parallel, ending when they are all
 * done.
 */
export class ParallelAnimation extends CompositeAnimation implements Animation {
  update(delta: Delta): boolean {
    let maxConsumed = 0
    for (let i = 0; i < this.animations.length;) {
      const animationDelta = new Delta(delta.getRemaining())
      const done = this.animations[i].update(animationDelta)
      if (done) {
        if (i < this.animations.length - 1) {
          this.animations[i] = this.animations.pop()!
        } else {
          this.animations.pop()
        }
      } else {
        i++
      }
      maxConsumed = Math.max(maxConsumed, delta.getRemaining() - animationDelta.getRemaining())
    }
    delta.consumeAtMost(maxConsumed)
    return this.isDone()
  }

  protected baseName(): string {
    return 'parallel'
  }
}

/**
 * An animation that plays sub-animations one after the other, ending when they
 * are all done.
 */
export class SequentialAnimation extends CompositeAnimation implements Animation {
  update(delta: Delta): boolean {
    while (this.animations.length > 0 && !delta.isEmpty()) {
      const done = this.animations[0].update(delta)
      if (done) {
        this.animations.shift()
      } else {
        break
      }
    }
    return this.isDone()
  }

  protected baseName(): string {
    return 'sequential'
  }
}

/**
 * A sequential animation that's (conceptually) always playing.
 */
export class Timeline extends SequentialAnimation {
  push(...animations: Animation[]) {
    super.push(...animations)
    if (!this.isDone()) {
      ANIMATION_MANAGER.play(this)
    }
  }

  protected baseName(): string {
    return 'timeline'
  }
}

class AnimationManager {
  private readonly animation: ParallelAnimation = new ParallelAnimation()
  private requestId: number | null = null
  private lastTimestamp: DOMHighResTimeStamp | null = null

  private readonly updateBound: (now: DOMHighResTimeStamp) => void = this.update.bind(this)

  play(animation: Animation) {
    this.animation.push(animation)
    if (this.requestId == null) {
      this.requestId = requestAnimationFrame(this.update.bind(this))
    }
  }

  private update(now: DOMHighResTimeStamp) {
    // If we get ridiculously high frame times for whatever reason, clamp them.
    const delta = new Delta(Math.min(
        (this.lastTimestamp == null ? 0 : now - this.lastTimestamp) / 1000,
        1/10))

    const done = this.animation.update(delta)

    if (done) {
      this.requestId = null
      this.lastTimestamp = null
    } else {
      this.requestFrame()
      this.lastTimestamp = now
    }
  }

  private requestFrame() {
    this.requestId = requestAnimationFrame(this.updateBound)
  }
}

export const ANIMATION_MANAGER = new AnimationManager()

const requestAnimationFrame: (callback: (now: DOMHighResTimeStamp) => void) => number =
  window.requestAnimationFrame ||
  function(callback: (now: DOMHighResTimeStamp) => void) {
    return window.setTimeout(() => callback(window.performance.now()), 16)
  }
