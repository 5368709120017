import { SoundEffect } from './soundeffect'

import soundsJson from '../../sounds/sounds.json'
import soundsMp3 from '../../sounds/sounds.mp3'
import soundsWebm from '../../sounds/sounds.webm'

// Patch up the JSON, both because audiosprite uses an old format (urls instead
// of src) and because the file names are different.
const sounds: IHowlProperties = {
  src: [soundsWebm, soundsMp3],
  sprite: soundsJson.sprite,
}

export const Sounds = {
  // The lines below are parsed by sounds/Makefile, so keep the format consistent!
  TILE_FLIP: new SoundEffect(sounds, 'sound_ex_machina_Button_Tick.wav'),
  TOUCH_DOWN: new SoundEffect(sounds, 'zapsplat_cartoon_pluck_hair_001_27052.wav'),
  ERROR: new SoundEffect(sounds, 'zapsplat_multimedia_game_incorrect_buzz_tone_004_26400.wav'),
  STASH: new SoundEffect(sounds, 'zapsplat_cartoon_whoosh_whip_away_001_26700.wav'),
  UNSTASH: new SoundEffect(sounds, 'zapsplat_cartoon_whoosh_whip_away_002_26701.wav'),
  BONUS: new SoundEffect(sounds, 'cartoon_twang_bounce_boing_spring_002.wav'),
  UNDO: new SoundEffect(sounds, 'aaj_0259_SldPrjctr1Clck1.wav'),
  GAME_OVER: new SoundEffect(sounds, 'zapsplat_cartoon_twang_spring_004_11477.wav'),
  BUTTON_CLICK: new SoundEffect(sounds, 'zapsplat_household_clothes_peg_plastic_click_close_003_20551.wav'),
}
