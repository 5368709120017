import { Sounds } from '../audio'
import { Elt } from '../render'
import { EventSender } from '../utils'

/**
 * An element with button-like behaviour. Styling can be done through adding
 * additional CSS classes.
 */
export class Button extends Elt {

  public readonly clicked = new EventSender<void>()

  private disabled: boolean = false

  constructor(text: string = '') {
    super()
    this
      .addClass('button')
      .setText(text)
      .addEventListener('mousedown', this.onPointerDown.bind(this))
      .addEventListener('touchstart', this.onPointerDown.bind(this))
      .addEventListener('click', this.onClick.bind(this))
  }

  setDisabled(disabled: boolean) {
    this.disabled = disabled
    this.toggleClass('disabled', disabled)
  }

  private onPointerDown(e: Event) {
    if (!this.disabled) {
      Sounds.BUTTON_CLICK.play({ pitch: 1.0, pitchRandomization: 0.03 });
    }
  }

  private onClick(e: Event) {
    e.preventDefault();
    if (!this.disabled) {
      Sounds.BUTTON_CLICK.play({ pitch: 2.0, pitchRandomization: 0.03 });
      this.clicked.emit();
    }
  }
}

/**
 * A button that can be toggled. Currently only suitable for use in the menu.
 */
export class ToggleButton extends Button {
  public readonly toggled = new EventSender<boolean>()

  private readonly checkMark: Elt
  private checked!: boolean

  constructor(text: string, checked: boolean) {
    super('')
    this.checkMark = new Elt('span')
        .addClass('check-mark')
    this.addChild(this.checkMark)
    this.addChild(new Elt('span').setText(text))
    this.setChecked(checked)
    this.clicked.on(this.onClicked.bind(this))
  }

  private onClicked() {
    this.setChecked(!this.checked)
    this.toggled.emit(this.checked)
  }

  private setChecked(checked: boolean) {
    this.checked = checked
    this.checkMark.toggleClass('checked', checked)
  }
}
