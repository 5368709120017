/**
 * Returns y such that min <= y < max and y == x + k * (max - min) for some
 * integer k.
 */
export function modBetween(x: number, min: number, max: number) {
  const range = max - min
  return (((x - min) % range) + range) % range + min
}

export function clamp(x: number, min: number, max: number) {
  return x < min ? min : x > max ? max : x
}

export function lerp(a: number, b: number, f: number) {
  return (1 - f) * a + f * b
}

export function square(x: number) {
  return x * x
}
