import { clamp, Vec2 } from '../math'

export class Elt {
  private readonly e: HTMLElement

  private animated: boolean = false
  private position: Vec2 = Vec2.ZERO
  private translation: Vec2 = Vec2.ZERO
  private rotation: number = 0
  private scale: number = 1
  private visible: boolean = true
  private opacity: number = 1

  constructor(from: HTMLElement | string = 'div') {
    if (typeof from == 'string') {
      if (from.charAt(0) == '#') {
        const e = document.getElementById(from.substr(1))
        if (!e) {
          throw new Error(`Cannot find element with id "${e}"`)
        }
        this.e = e
      } else {
        this.e = document.createElement(from)
      }
    } else {
      this.e = from
    }
  }

  clear(): this {
    this.e.innerHTML = ''
    return this
  }

  addChild(child: Elt): this {
    this.e.appendChild(child.e)
    return this
  }

  removeChild(child: Elt): this {
    this.e.removeChild(child.e)
    return this
  }

  removeFromParent() {
    const parent = this.e.parentNode
    if (parent) {
      parent.removeChild(this.e)
    }
  }

  addClass(classes: string | string[]): this {
    if (typeof classes == 'string') {
      classes = classes.split(' ')
    }
    for (const clazz of classes) {
      this.e.classList.add(clazz)
    }
    return this
  }

  removeClass(classes: string | string[]): this {
    if (typeof classes == 'string') {
      classes = classes.split(' ')
    }
    for (const clazz of classes) {
      this.e.classList.remove(clazz)
    }
    return this
  }

  hasClass(clazz: string): boolean {
    return this.e.classList.contains(clazz)
  }

  toggleClass(clazz: string, present: boolean): this {
    if (present) {
      this.e.classList.add(clazz)
    } else {
      this.e.classList.remove(clazz)
    }
    return this
  }

  setAttribute(attribute: string, value: string): this {
    this.e.setAttribute(attribute, value)
    return this
  }

  setStyle(key: string, value: string): this {
    this.e.style.setProperty(key, value)
    return this
  }

  setText(text: string): this {
    this.e.innerText = text
    return this
  }

  setHtml(html: string): this {
    this.e.innerHTML = html
    return this
  }

  getPosition(): Vec2 {
    return this.position
  }

  setPosition(pos: Vec2): this {
    this.position = pos
    this.setStyle('position', 'absolute')
    this.setStyle('left', `${pos.x}em`)
    this.setStyle('top', `${pos.y}em`)
    return this
  }

  setWidth(width: number): this {
    this.setStyle('width', `${width}em`)
    return this
  }

  setHeight(height: number): this {
    this.setStyle('height', `${height}em`)
    return this
  }

  setSize(size: Vec2): this {
    this.setWidth(size.x)
    this.setHeight(size.y)
    return this
  }

  /**
   * When enabled, adds a transformZ(0) to the transform, which causes the
   * browser (or at least Chrome) to put the element onto a separate layer.
   * This saves time on painting, because all it has to do is move the layer
   * around in the compositor. For more details, see:
   * https://developers.google.com/web/fundamentals/performance/rendering/stick-to-compositor-only-properties-and-manage-layer-count
   */
  setAnimated(animated: boolean): this {
    this.animated = animated
    this.updateTransform()
    return this
  }

  getTranslation(): Vec2 {
    return this.translation
  }

  setTranslation(translation: Vec2, update: boolean = true): this {
    this.translation = translation
    if (update) {
      this.updateTransform()
    }
    return this
  }

  getRotation(): number {
    return this.rotation
  }

  setRotation(rotation: number, update: boolean = true): this {
    this.rotation = rotation
    if (update) {
      this.updateTransform()
    }
    return this
  }

  getScale(): number {
    return this.scale
  }

  setScale(scale: number, update: boolean = true): this {
    this.scale = scale
    if (update) {
      this.updateTransform()
    }
    return this
  }

  setTransform(transform: string): this {
    this.setStyle('transform', transform)
    this.setStyle('-moz-transform', transform)
    this.setStyle('-webkit-transform', transform)
    return this
  }

  updateTransform() {
    this.setTransform(`translate(${this.translation.x}em, ${this.translation.y}em) rotate(${this.rotation}deg) scale(${this.scale})${this.animated ? ' translateZ(0)' : ''}`)
  }

  setVisible(visible: boolean): this {
    this.visible = visible
    this.setStyle('display', visible ? 'block' : 'none')
    return this
  }

  isVisible(): boolean {
    return this.visible
  }

  getOpacity(): number {
    return this.opacity
  }

  setOpacity(opacity: number): this {
    this.opacity = clamp(opacity, 0, 1)
    this.setStyle('opacity', `${this.opacity}`)
    return this
  }

  addEventListener(event: string, listener: any, options?: AddEventListenerOptions): this {
    this.e.addEventListener(event, listener, options)
    return this
  }

  removeEventListener(event: string, listener: any): this {
    this.e.removeEventListener(event, listener)
    return this
  }

  getRelativeMousePos(clientX: number, clientY: number): Vec2 {
    const rect = this.e.getBoundingClientRect()
    const px = new Vec2(clientX, clientY).sub(new Vec2(rect.left, rect.top))
    const fontSizeStr = window.getComputedStyle(this.e)!.fontSize!
    const fontSize = parseFloat(/^(.*)px$/.exec(fontSizeStr)![1])
    return px.scl(1 / fontSize)
  }
}
